import '../App.css';
import '../styling/show.css';
import React from 'react'
import dateFormat from 'dateformat';

const tmdbPosterBaseUrl = "https://image.tmdb.org/t/p/w92";

class ShowDetails extends React.Component {
 
    constructor(props) {
        super(props);
        this.state = {
            isSearch: this.props.mode === "search" 
        } 
    } 

    divTapped(id) { 
        window.location.href = '/show/' + id; 
    }

    renderCommaSeperated(data, title) {  
        return (
            <div className={`show-data ${ this.state.isSearch ? "none" : "" }`}>
                <div className="title">{title}:</div>
                <div className="data">{ data ? data.map(function(item, index) { return ( index ? ', ' : '') + item.name } ) : ""}</div>
            </div>
        )
    }

    render() { 
        return (  
            <div onClick={ this.state.isSearch ? () => this.divTapped(this.props.show.id) : null } 
                className={`show ${ this.state.isSearch ? "clickable" : "" }`} key={this.props.show.id}>
                <div className="graphic"> 
                    <img className="poster shadow" width="92" height="138" alt="poster"
                        src={this.props.show.poster_path ?
                            tmdbPosterBaseUrl + this.props.show.poster_path :
                            window.location.origin + "/poster-placeholder.png"} />
                </div>
                <div className="details">
                    <div className="name">{this.props.show.name}</div>
                    <div className="overview">{this.props.show.overview}</div> 
                
                    {this.renderCommaSeperated(this.props.show.genres, "Genres")}  
                    {this.renderCommaSeperated(this.props.show.networks, "Networks")}    
                    {this.renderCommaSeperated(this.props.show.created_by, "Created By")}
                    <div className={`show-data ${ this.state.isSearch ? "none" : "" }`}><div className="title">Number of seasons:</div>   <div className="data">{this.props.show.number_of_seasons}</div></div>   
                    <div className={`show-data ${ this.state.isSearch ? "none" : "" }`}><div className="title">Number of episodes:</div>  <div className="data">{this.props.show.number_of_episodes}</div> </div>
                    <div className={`show-data ${ this.state.isSearch ? "none" : "" }`}><div className="title">Status:</div>              <div className="data">{this.props.show.status}</div> </div>
             
                    <div className="show-data">                                         <div className="title">First aired:</div>         <div className="data">{dateFormat(this.props.show.first_air_date, "mmmm dS, yyyy")}</div>  </div>                                  
                    <div className={`show-data ${ this.state.isSearch ? "none" : "" }`}><div className="title">Last aired:</div>          <div className="data">{dateFormat(this.props.show.last_air_date, "mmmm dS, yyyy")}</div>    </div>                    
                </div>
            </div>
        )
    }
} 

export default ShowDetails;
