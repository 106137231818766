import React from 'react'
import styled from 'styled-components'
import dateFormat from 'dateformat';
import EdiText from 'react-editext';
import '../App.css';
import '../styling/episode.css';

const StyledEdiText = styled(EdiText)`
  button {
    border: none;
  } 
  button[editext="edit-button"] {
    background: transparent;
    border: none;
  } 
  strong {
    cursor: pointer;
    color: black;
  }
  .styles_Editext__hint__2QU2S {
    // color: red;
  }
}`

const url = 'https://api.themoviedb.org/3/authentication/token/new';
const options = {
  method: 'GET',
  headers: {
    accept: 'application/json',
    Authorization: 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJhYTk3NmI5OTQyYmI3OTNhNjJmMDExOTM4MDhiZWM5MCIsInN1YiI6IjU4ZjdjNzQ1OTI1MTQxMDhlZDAwMTAxZiIsInNjb3BlcyI6WyJhcGlfcmVhZCJdLCJ2ZXJzaW9uIjoxfQ.cnGp43O53UyE0dBB0SboZ-50vyDqmU_iQZKJSqyKidg'
  }
};

const apiKey = "aa976b9942bb793a62f01193808bec90";

class Episode extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      mode: this.props.mode,
      episode: this.props.episode,
      showId: this.props.showId,
      onEpisodeSave: this.props.onEpisodeSave
    };
  }

  onEpisodeRevert = () => { 
    const { episode, showId } = this.state;
    fetch(`https://api.themoviedb.org/3/tv/${showId}/season/${episode.season_number}/episode/${episode.episode_number}?api_key=${apiKey}&language=en-US`)
      .then(response => response.json())
      .then((data) => {
        episode.overview = data.overview;
        console.log('episode.overview:', episode.overview);

        this.setState({
          episode: episode
        })
        console.log('episode.overview: fetched');
      })
      .catch((error) => {
        console.error('Error:', error);
        alert("Failed to load.");
      });
  }

  onEpisodeSet = val => {
    this.setState({
      episode: val
    })
  }

  onEpisodeChange = val => {
    const { onEpisodeSave, episode } = this.state;
    onEpisodeSave(val, episode);
  }

  render() {
    const { episode, mode } = this.state;
    return (

      mode === "voting" ? (
        <div className={mode === "voting" ? "divTableRow clickable" : "none"}>
          <div className="divTableCell">
            <div className="episode">
              <div className="title">Season: {episode.season_number}, Episode: {episode.episode_number}: <span className="name">{episode.name}</span></div>
              <div className={episode.overview === "" ? "missing-description" : "overview"}>
                {episode.overview}
              </div>
            </div>
          </div>
        </div>
      ) : (
          <div className="divTableRow">
            <div className="divTableCell center">{episode.episode_number}</div>
            <div className="divTableCell">
              <div className="episode">
                <div className="title">Season: {episode.season_number}, Episode: {episode.episode_number}: <span className="name">{episode.name}</span></div>
                <div className={episode.overview === "" ? "overview missing-description" : "overview"}>
                  <StyledEdiText
                    inputProps={{
                      rows: 5,
                      placeholder: 'Description of 10 characters or more is required',
                    }}
                    hint={
                      <div>Revert to sourced description? Click <strong onClick={() => this.onEpisodeRevert()}>HERE</strong></div>
                    }
                    hideIcons={mode === "voting" ? true : false}
                    cancelOnEscape
                    showButtonsOnHover
                    validationMessage="Please type at least 10 characters."
                    validation={val => val.length >= 20}
                    value={episode.overview === "" ? "Episode description required" : episode.overview}
                    type="textarea"
                    editOnViewClick={mode === "voting" ? false : true}
                    onSave={this.onEpisodeChange}
                  />
                </div>
                <div className="etc">
                  <span className="episodedata">
                    <strong>Aired:</strong>
                    <span className="lightGray-value">{dateFormat(episode.air_date, "mmmm dS, yyyy")}</span>
                    <span>
                      <strong>WINS:</strong>
                      <span className="etc-value lightGray-value">100</span>
                      <strong>LOSSES:</strong>
                      <span className="etc-value lightGray-value">50</span>
                      <strong>WIN RATIO:</strong>
                      <span className="etc-value lightGray-value">50%</span>
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
      )
    )
  }
}

export default Episode; 