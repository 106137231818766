import React from 'react'
import Episode from './Episode';
import '../App.css';
import '../styling/vote.css';

class Vote extends React.Component {

    constructor(props) {
        super(props);
        this.voteLeftRef = React.createRef();
        this.voteRightRef = React.createRef(); 
    }

    shuffleArray(ar) {
        var array = ar;
        let i = array.length - 1;
        for (; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            const temp = array[i];
            array[i] = array[j];
            array[j] = temp;
        }
        return array;
    }
 
    randomizeEpisodes() {
        if (this.props.allEpisodes.length>0) {
            console.log("randomizingEpisode()")
            var allEps = [];
            do {
                console.log("shuffling");
                allEps = this.shuffleArray(this.props.allEpisodes);
            } while (allEps[0].overview==="" || allEps[1].overview==="")
            this.voteLeftRef.current.onEpisodeSet(allEps[0]);
            this.voteRightRef.current.onEpisodeSet(allEps[1]);            
        }
    }
 
    vote(winner, loser) {
        console.log(`vote ${winner}, ${loser}`); 
        this.randomizeEpisodes();
    }
    
    componentDidMount () {
        this.randomizeEpisodes(); 
    }
    
    render() {
        return (
            this.props.allEpisodes.length===0 ? ( 
                <div>Loading...</div>
            ) : (
                    <div>
                        <div className="seasons"></div>
                        <div sytle="width: 100%">
                            <img height="30" width="228" alt="episode listings by season"
                                src={process.env.PUBLIC_URL + '/votingboxbg2.png'} />
                        </div>
                        <div>
                            <div className="left-vote" onClick={() => { this.vote(this.props.allEpisodes[0].id, this.props.allEpisodes[1].id) }} >
                                <Episode episode={this.props.allEpisodes[0]} mode="voting" ref={this.voteLeftRef} />
                            </div>
                            <div className="right-vote" onClick={() => { this.vote(this.props.allEpisodes[1].id, this.props.allEpisodes[0].id) }} >
                                <Episode episode={this.props.allEpisodes[1]} mode="voting" ref={this.voteRightRef} />
                            </div>
                        </div>
                    </div>
                )
        )
    }
}

export default Vote;



// import React from 'react'
// import Episode from './Episode';
// import '../App.css';
// import '../styling/vote.css';

// class Vote extends React.Component {

//     constructor(props) {
//         super(props);
//         this.voteLeftRef = React.createRef();
//         this.voteRightRef = React.createRef();
//     }

//     shuffleArray(ar) {
//         var array = ar;
//         let i = array.length - 1;
//         for (; i > 0; i--) {
//             const j = Math.floor(Math.random() * (i + 1));
//             const temp = array[i];
//             array[i] = array[j];
//             array[j] = temp;
//         }
//         return array;
//     }
 
//     randomizedEpisodes() {
//         console.log("randomizingEpisode()")
//         var allEps = [];
//         do {
//             console.log("shuffling");
//             allEps = this.shuffleArray(this.props.allEpisodes);
//         } while (allEps[0].overview==="" || allEps[1].overview==="")
//         this.voteLeftRef.current.onEpisodeSet(allEps[0]);
//         this.voteRightRef.current.onEpisodeSet(allEps[1]);
//     }

//     vote(winner, loser) {
//         console.log(`vote ${winner}, ${loser}`); 
//     }

//     render() {
//         this.randomizedEpisodes(); 
//         return (
//             this.props.allEpisodes.length===0 ? (
//                 <div>Loading...</div>
//             ) : (
//                     <div>
//                         <div className="seasons"></div>
//                         <div sytle="width: 100%">
//                             <img height="30" width="228" alt="episode listings by season"
//                                 src={process.env.PUBLIC_URL + '/votingboxbg2.png'} />
//                         </div>
//                         <div>
//                             <div className="left-vote" onClick={() => { this.vote(allEpisodes[0].id, allEpisodes[1].id) }} >
//                                 {allEpisodes[0] &&
//                                     <Episode episode={allEpisodes[0]} mode="voting" ref={this.voteLeftRef} />
//                                 }
//                             </div>
//                             <div className="right-vote" onClick={() => { this.vote(allEpisodes[1].id, allEpisodes[0].id) }}>
//                                 {allEpisodes[1] &&
//                                     <Episode episode={allEpisodes[1]} mode="voting" ref={this.voteRightRef} />
//                                 }
//                             </div>
//                         </div>
//                     </div>
//                 )
//         )
//     }
// }

// export default Vote;