import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ReactComponent as Logo } from './icons/rank.itall.online.svg';
import Home from './components/Home.js';
import Show from './components/Show.js';
import About from './components/About.js'; 
import Contact from './components/Contact.js';
import Error from './components/Error.js';
import Navigation from './components/Navigation.js';
import Footer from './components/Footer.js';

require('dotenv').config() 

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <div id="content" className="content"> 
            <div className="header">  
              <Logo />
              <Navigation />            
            </div> 
            <Switch>
              <Route path="/" component={Home} exact />
              <Route path="/show/:id" component={Show} />
              <Route path="/about" component={About} />
              <Route path="/contact" component={Contact} />
              <Route component={Error} />
            </Switch> 
            <Footer /> 
          </div> 
      </BrowserRouter>
    );
  }
}

export default App;
 