import React from 'react';
import ShowDetails from './ShowDetails.js';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Seasons from './Seasons.js';
import Vote from './Vote.js';
import MapSeries from 'promise-map-series';
import 'react-tabs/style/react-tabs.css';
import '../App.css';

const apiKey = "aa976b9942bb793a62f01193808bec90";

const url = 'https://api.themoviedb.org/3/authentication/token/new';
const options = {
  method: 'GET',
  headers: {
    accept: 'application/json',
    Authorization: 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJhYTk3NmI5OTQyYmI3OTNhNjJmMDExOTM4MDhiZWM5MCIsInN1YiI6IjU4ZjdjNzQ1OTI1MTQxMDhlZDAwMTAxZiIsInNjb3BlcyI6WyJhcGlfcmVhZCJdLCJ2ZXJzaW9uIjoxfQ.cnGp43O53UyE0dBB0SboZ-50vyDqmU_iQZKJSqyKidg'
  }
};

class Show extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            show: [],
            allEpisodes: []
        };
    }

    loadShow(id) {
        console.log(`live loading: ${id}`);
        fetch(`https://api.themoviedb.org/3/tv?language=en-US`, options)
            .then(response => response.json())
            .then(show => {
                MapSeries(show.seasons, function (item) {
                    const theMovieDbSeasonData = fetch(`https://api.themoviedb.org/3/tv/${id}/season/${item.season_number}`, options).then(r => r.json());
                    const customizedEpisodes = fetch(`${process.env.REACT_APP_RANKITALL_SERVER_API_URL}/show/${id}/season/${item.season_number}`, options).then(r => r.json());
                    return Promise.all([theMovieDbSeasonData, customizedEpisodes])
                        .then(r => ({
                            "theMovieDbSeasonData": r[0],
                            "customizedEpisodes": r[1]
                        }))
                        .then(
                            (result) => {
                                result.theMovieDbSeasonData.episodes.forEach(episode => {
                                    result.customizedEpisodes.forEach(customizedElement => {
                                        if (episode.id.toString() === customizedElement.episodeId) {
                                            episode.overview = customizedElement.overview;
                                        }
                                    })
                                });
                                return result.theMovieDbSeasonData;
                            }
                        );
                }).then(results => {
                    show.seasons = results;
                    var allEpisodes = [];
                    show.seasons && show.seasons.forEach(season => {
                        // eslint-disable-next-line
                        season.episodes.map((ep) => {
                            if (ep.season_number > 0) {
                                allEpisodes.push(ep)
                            }
                        })
                    });
                    this.setState({
                        isLoaded: true,
                        show: show,
                        allEpisodes: allEpisodes
                    });
                })
            });
    }

    componentDidMount() {
        this.loadShow(this.props.match.params.id);
    }
 
    handleEpisodeSave = (val, episode) => {
        console.log("show handleEpisodeSave()");
        console.log( episode );
        console.log( val );
        const data = {
            name: episode.name,
            overview: val,
            episodeId: `${episode.id}`,
            showId: `${this.state.show.id}`,
            seasonNumber: `${episode.season_number}`
        };
        fetch(`${process.env.REACT_APP_RANKITALL_SERVER_API_URL}/episode/upsert`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(data => {
                console.log('Success:', data);
                alert("Saved");
                this.loadShow(this.props.match.params.id);
            })
            .catch((error) => {
                console.error('Error:', error);
                alert("Failed to save.");
            });
    } 
    
    render() {
        return (
            <div className="show">
                <ShowDetails show={this.state.show} mode="full" />
                <Tabs defaultactivekey="voting">
                    <TabList>
                        <Tab key="voting">VOTING PAGE</Tab>
                        <Tab key="results">VOTING RESULTS & EPISODES</Tab>
                    </TabList>
                    <TabPanel key="voting_panel">
                        <Vote show={this.state.show} allEpisodes={this.state.allEpisodes} />
                    </TabPanel>
                    <TabPanel key="results_panel">
                        <Seasons show={this.state.show} allEpisodes={this.state.allEpisodes} onEpisodeSave={this.handleEpisodeSave} onEpisodeRevert={this.handleEpisodeRevert}  />
                    </TabPanel>
                </Tabs>
            </div>
        )
    }
}

export default Show;
