import React from 'react'
import '../App.css'; 
//const tmdbLogoUrl = "https://www.themoviedb.org/assets/2/v4/logos/v2/blue_long_2-9665a76b1ae401a510ec1e0ca40ddcb3b0cfe45f1d51b77a308fea0845885648.svg"

class Footer extends React.Component {
    render() {
        return ( 
            <div className="footer"> 
                Copyright &copy; 2020
            </div> 
        )
    } 
}
export default Footer;
