import React from 'react'
import ShowDetails from './ShowDetails.js'
import '../App.css';

const apiKey = "aa976b9942bb793a62f01193808bec90"
const tmdbApiUrl = "https://api.themoviedb.org/3/search/tv?language=en-US&page=1&include_adult=false&api_key=" + apiKey

class Search extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            searchTerm: "star ",
            error: null,
            isLoaded: false,
            shows: [],
            page: null,
            pages: null,
            totalResults: null
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.setState({
            searchTerm: event.target.value
        }, () => {
            this.search(this.state.searchTerm);
        });
    }

    search(searchTerm) {
        if (searchTerm !== "") {
            fetch(tmdbApiUrl + "&query=" + searchTerm)
                .then(res => res.json())
                .then(
                    (result) => {
                        this.setState({
                            isLoaded: true,
                            page: result.page,
                            pages: result.total_pages,
                            totalResults: result.total_results,
                            shows: result.results
                        });
                    },
                    // Note: it's important to handle errors here
                    // instead of a catch() block so that we don't swallow
                    // exceptions from actual bugs in components.
                    (error) => {
                        this.setState({
                            isLoaded: true,
                            error
                        });
                    }
                )
        } else {
            this.setState({
                isLoaded: true,
                shows: []
            });
        }
    }

    componentDidMount() {
        this.search(this.state.searchTerm);
    }

    render() {
        const { error, isLoaded, shows, searchTerm } = this.state;
        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return <div>Loading...</div>;
        } else {
            return (
                <>
                    <div className="search-input">
                        <input className="search-input-search"
                            type="text"
                            value={searchTerm}
                            onChange={this.handleChange}
                            placeholder="Enter show search term" />
                        <div className="search-input-stats"><span className={this.state.totalResults > 0 ? "" : "none"}>Page {this.state.page} of {this.state.pages} - Total results {this.state.totalResults}</span></div>
                    </div>
                    <div className={shows && shows.length > 0 ? "results" : "none"}>
                        {shows.map(show => (
                            <ShowDetails show={show} mode="search" key={show.id} />
                        ))}
                    </div>
                    <div className={shows && shows.length === 0 ? "result-shows empty" : "none"}>Nothing to show here. Try narrowing your search.</div>
                </>
            )
        }
    }
}


export default Search;
