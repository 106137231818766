import React from 'react'
import '../App.css';

const apiKey = "aa976b9942bb793a62f01193808bec90"
const tmdbApiUrl = "https://api.themoviedb.org/3/search/tv?language=en-US&page=1&include_adult=false&api_key=" + apiKey
const tmdbPosterBaseUrl = "https://image.tmdb.org/t/p/w45"

class TopTen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            searchTerm: "star",
            isSearch: true,
            topTen: []
        };
    }

    search(searchTerm) {
        if (searchTerm !== "") {
            fetch(tmdbApiUrl + "&query=" + searchTerm)
                .then(res => res.json())
                .then(
                    (result) => {
                        this.setState({
                            isLoaded: true,
                            topTen: result.results
                        });
                    },
                    // Note: it's important to handle errors here
                    // instead of a catch() block so that we don't swallow
                    // exceptions from actual bugs in components.
                    (error) => {
                        this.setState({
                            isLoaded: true,
                            error
                        });
                    }
                )
        } else {
            this.setState({
                isLoaded: true,
                items: []
            });
        }
    }

    componentDidMount() {
        this.search(this.state.searchTerm);
    }

    divTapped(id) {
        window.location.href = '/show/' + id;
    }

    render() {
        return (
            <div className="top-ten">
                {this.state.topTen.slice(0, 20).map(show =>  
                    <img onClick={this.state.isSearch ? () => this.divTapped(show.id) : null} 
                        key={show.id}
                        className={show.poster_path ? "poster top-ten-graphic" : 'none'} 
                        alt=""
                        src={tmdbPosterBaseUrl + show.poster_path} /> 
                )}
            </div>
        )
    }
}

export default TopTen;
