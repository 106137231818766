import React from 'react';
import Episode from './Episode';
import '../App.css';
import '../styling/episode.css';
import '../styling/loader.css';

class Season extends React.Component {

    shouldComponentUpdate(nextProps, nextStage) {
        console.log("Season shouldComponentUpdate() called");
        return false;
    }

    render() {
        console.log(`Season render() called on season ${this.props.season_number}`);
        console.log(`Season render() ${this.props.episodes}`);
        return (
            <div className="divTable">
                <div className="divTableRow divTableHeading">
                    <div className="divTableCell ep-rank-header">rank</div>
                    <div className="divTableCell ep-details-header">episode details</div>
                </div>
                {this.props.episodes.sort(function(a,b){
                        return a.season_number - b.season_number ? a.season_number - b.season_number : a.episode_number - b.episode_number 
                    }).map((episode, key) => (
                    ((this.props.season_number === "all") || (episode.season_number === this.props.season_number)) &&
                    <Episode episode={episode} key={key} showId={this.props.showId} onEpisodeSave={this.props.onEpisodeSave} />
                ))}
            </div>
        )
    }
}

export default Season;
