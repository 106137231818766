import React from 'react';
import Search from './Search.js';
import TopTen from './Topten.js';

const home = () => {
    return (
        <div>
            <TopTen />
            <Search />
        </div>
    );
}

export default home;