import React from 'react'
import Season from './Season.js'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import '../App.css';

class Seasons extends React.Component {

    
    shouldComponentUpdate(nextProps, nextStage) {
        console.log("Seasons shouldComponentUpdate() called");
        return true;
    }

    render() {
        var show = this.props.show;
        var allEpisodes = this.props.allEpisodes;
        return (
            !show || !show.seasons ? (
                <div>Loading...</div>
            ) : (
                <div className="seasons">
                    <div sytle="width: 100%">
                        <img height="30" width="228" alt="episode listings by season"
                            src={process.env.PUBLIC_URL + '/episodelistings.png'} />
                    </div>
                    <Tabs forcerendertabpanel="true">
                        <TabList>
                            {show && show.seasons && show.seasons.map((season) => season.season_number > 0 ? (
                                <Tab key={season.season_number}>{season.season_number}</Tab>
                            ) : null)}
                            <Tab key="all">All</Tab>
                        </TabList>
                        {show && show.seasons && show.seasons.map((season) => season.season_number > 0 ? (
                            <TabPanel key={season.id}>
                                <Season key={season.id} showId={show.id} 
                                        season_number={season.season_number} 
                                        season={season} 
                                        episodes={allEpisodes.filter(ep => ep.season_number === season.season_number)} 
                                        onEpisodeSave={this.props.onEpisodeSave} 
                                        onEpisodeRevert={this.props.onEpisodeRevert}  />
                            </TabPanel>
                        ) : null)}
                        <TabPanel key="all">
                            <Season key="all"   
                                    showId={show.id} 
                                    season_number="all" 
                                    episodes={allEpisodes} 
                                    onEpisodeSave={this.props.onEpisodeSave} 
                                    onEpisodeRevert={this.props.onEpisodeRevert} />
                        </TabPanel>
                    </Tabs>
                </div>            
            )
        )
    }
}

export default Seasons;